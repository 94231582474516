import TextInputField from "../TextInputField/TextInputField"

export const AddressInputFieldGroup = (props: {
  fieldName?: string,
  errors: any,
  touched: any,
  message?: string,
  disableFields?: {
    address?: boolean,
    address2?: boolean,
    city?: boolean,
    state?: boolean,
    zip?: boolean
  },
  fieldMessages?: {
    address?: string,
    address2?: string,
    city?: string,
    state?: string,
    zip?: string
  }
  fieldLabels?: {
    address?: string,
    address2?: string,
    city?: string,
    state?: string,
    zip?: string
  }
}) => {
  const {
    fieldName,
    errors,
    touched,
    message,
    disableFields,
    fieldMessages,
    fieldLabels = {}
  } = props
  return (
    <>
      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}address`}
        // fieldLabel={'Street'}
        fieldLabel={fieldLabels.address || 'Street'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Street'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}address2`}
        // fieldLabel={'Apt#'}
        fieldLabel={fieldLabels.address2 || 'Apt#'}
        errors={errors}
        touched={touched}
        placeholder={'Apt#'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}city`}
        // fieldLabel={'City'}
        fieldLabel={fieldLabels.city || 'City'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'City'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}state`}
        // fieldLabel={'State'}
        fieldLabel={fieldLabels.state || 'State'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'State'}
        disabled={disableFields?.state}
        fieldSubLabel={fieldMessages?.state}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}zip`}
        // fieldLabel={'Zip Code'}
        fieldLabel={fieldLabels.zip || 'Zip Code'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Zip Code'}
      />
    </>
  )
}
